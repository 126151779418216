import React, { useState, useEffect, useLayoutEffect } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import Map from '../components/map'
import { navigate, graphql } from 'gatsby'
import '../styles/index-en.less'
import SEO from '../components/SEO'
import Drop from '../components/drop'
const loadText = require('src/utils').loadText
import { Button } from '../components/ui'
const url = loadText('url')
const static_data = loadText('index')
import { ConsultationPopButton } from '../components/form-biz'
import CountUpAnimation from '../components/block/CountUpAnimation'
import AnimationWrap from '../components/block/AnimationWrap'
import { lang } from '../utils'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import VisibilitySensor from 'react-visibility-sensor'
import newProductMap from '../utils/newProductsMap'
const Promo = require('../assets/index-promo.svg')
const PromoM = require('../assets/index-promo-m.svg')
const bannerSetting = {
  infinite: true,
  slidesToShow: 1,
  speed: 500,
  className: 'center',
  autoplaySpeed: 5000,
  dots: true,

  rows: 1,
  slidesPerRow: 1,
  autoplay: true,
}
const settings = {
  className: 'center',
  infinite: true,
  slidesToShow: 4,
  speed: 500,
  autoplaySpeed: 5000,
  rows: 2,
  slidesPerRow: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        rows: 2,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 640,
      settings: {
        rows: 4,
        slidesToShow: 2,
      },
    },
  ],
}

const TopIntro = () => {
  useEffect(() => {
    const ct: any = document.querySelector('.index-topContainer')
    const bg: any = document.querySelector('.index-topBgContainer')
    const bgDot: any = document.querySelector('.index-topBg-dotWrapper')
    const listener = (e: any) => {
      const windowWidth = window.innerWidth / 5
      const windowHeight = window.innerHeight / 5
      const mouseX = e.clientX / windowWidth
      const mouseY = e.clientY / windowHeight
      bg.style.transform = `translate3d(-${mouseX}%, -${mouseY}%, 0)`
      bgDot.style.transform = `translate3d(${mouseX / 10}%, ${mouseY / 10}%, 0)`
    }
    ct.addEventListener('mousemove', listener)
    return () => bg.removeEventListener('mousemove', listener)
  }, [])
  return (
    <div className="index-topIntro">
      <div className="index-topIntro-fadeIn">
        <div className="index-topIntro-title semi-bold">
          {static_data.topBanner.title}
        </div>
        {/* <div className="index-topIntro-feature">{static_data.topBanner.subtitle}</div> */}
        <div
          className="index-topIntro-subtitle block-middle"
          style={{ maxWidth: '1000px' }}
          dangerouslySetInnerHTML={{ __html: static_data.topBanner.text }}
        />

        <div className="index-topIntro-btn">
          <Button
            size="l"
            onClick={() => {
              const link =
                'https://console.zenlayer.com/bmc/server/create?promotion_tag=2210_bmc'
              window.open(link)
            }}
          >
            Create a free account
          </Button>
          <ConsultationPopButton source="index" buttonClassName="white">
            {/* {static_data.topBanner.button} */}
            Schedule a free consultation
          </ConsultationPopButton>
          {/* <ConsultationPopButton
          // size="l"
          buttonClassName="white"
          buttonWidth={390}
          // width={400}

          // style={{ color: "#24B7FF", background: "#fff", marginLeft: "64px" }}
        >
          {static_data.topBanner.buttonRight}
        </ConsultationPopButton> */}
        </div>
      </div>
    </div>
  )
}

// export const ParallaxEffect = () => {
//   return (
//     <div className={`parallax-effect ${lang}`}>
//       <div className="parallax-effect-container">
//         <div style={{ position: "relative" }}>
//           <Drop
//             size="l"
//             //  style={{ top: "-90px" }}
//             color="#C4EBFF"
//           />
//         </div>
//         <div className="parallax-effect-pg" id="parallax-effect-pgId">
//           <div>
//             <img
//               // src={`/index/sdn1.png`}
//               src={static_data.parallaxEffect.logo}
//               alt={"cloud networking"}
//               className="product-logo"
//             />
//           </div>
//           <h1 className="parallax-effect-title ">
//             {static_data.parallaxEffect.title}
//           </h1>
//           {static_data.parallaxEffect.time && (
//             <div className="parallax-effect-subTitle">
//               {static_data.parallaxEffect.time}
//             </div>
//           )}

//           {/* <img
//             src={`/index/cdnRolled.png`}
//             alt={static_data.parallaxEffect.title}
//             className="product-logo"
//           /> */}

//           <div
//             className="parallax-effect-btn"
//             onClick={() => navigate(static_data.parallaxEffect.link)}
//           >
//             <Button width={390} className="white">
//               {static_data.parallaxEffect.button}
//             </Button>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

export const ProductIntroM = () => {
  //
  const keyToData = static_data.product.data.reduce((acc, cur) => {
    acc[cur._key] = cur
    return acc
  }, {})
  return (
    <div className="index-productIntro">
      <AnimationWrap>
        <div className="index-productIntro-feature mt-104 font-28 t-color block-middle">
          <div
            dangerouslySetInnerHTML={{ __html: static_data.product.feature[0] }}
          />
          <span className="index-productIntro-plus">+</span>
          <div
            dangerouslySetInnerHTML={{ __html: static_data.product.feature[1] }}
          />
        </div>
        <div className="mt-56  index-productIntro-sectWrap">
          <div className="index-productIntro-itemWrap">
            <div className="only-mobile-show">
              <div className="index-productIntro-itemContent">
                {static_data.product.dataMKey
                  .map((key) => keyToData[key])
                  .map(
                    (
                      { _key, name, description, color, hoverColor, features },
                      idx
                    ) => (
                      <div
                        className={`index-productIntro-item index-productIntro-item-${idx}`}
                        onClick={() => {
                          navigate(url[_key].link)
                        }}
                      >
                        {newProductMap[_key] && (
                          <img
                            src={'/new-badge.svg'}
                            className="new-badge1"
                            alt="new-product"
                          />
                        )}
                        <div className="index-productIntro-itemWrapper">
                          <div
                            style={{ background: color }}
                            className="index-productIntro-item-normal"
                          >
                            <div className="index-productIntro-itemTitle font-28 semi-bold">
                              {name}
                            </div>
                            <div className="index-productIntro-itemDesc font-20">
                              {description}
                            </div>
                            <picture>
                              <source
                                srcSet={`/header/${_key}-m.svg`}
                                media="(max-width: 640px)"
                              />
                              <img
                                src={`/header/${_key}.svg`}
                                alt={name}
                                className="product-logo"
                              />
                            </picture>
                          </div>
                          <div className="index-productIntro-item-hover">
                            <div
                              className="name semi-bold"
                              style={{ background: hoverColor }}
                            >
                              <Drop
                                color={hoverColor}
                                style={{ bottom: '-18px', left: '60px' }}
                              />
                              {name}
                            </div>
                            <ul className="mt-24">
                              {features.map((c) => (
                                <li
                                  dangerouslySetInnerHTML={{
                                    __html: c.replace(
                                      '<b>',
                                      `<b style="color: ${hoverColor}">`
                                    ),
                                  }}
                                  className="font-16"
                                />
                              ))}
                            </ul>
                            <div
                              className="learn-more font-20 semi-bold"
                              style={{ background: hoverColor }}
                            >
                              <img
                                src="/index/arrow-w.svg"
                                alt={static_data.subject.learnMore}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
      </AnimationWrap>
    </div>
  )
}
export const ProductIntro = () => {
  const Item = ({ _key, name, color, hoverColor, features }) => {
    // const [hover, setHover] = useState(false)
    // const toggleHover = () => {
    //   setHover(lastValue => !lastValue)
    // }
    // let dotHoverColor = _key == "cdn" ? "#5B87FF" : hoverColor
    let linkStyle = {
      // borderBottom: `4px solid ${hoverColor}`,
    }
    return (
      <div
        className={`item product-item product-item-${_key}`}
        style={{ background: color, ...linkStyle }}
        // onMouseEnter={() => toggleHover()}
        // onMouseLeave={() => toggleHover()}
        onClick={() => {
          navigate(url[_key].link)
        }}
      >
        <div className="font-20 semi-bold t-color">{name}</div>
        <ul>
          {features.map((c) => (
            <li className="mt-16" style={{ color: hoverColor }}>
              <span>{c}</span>
            </li>
          ))}
        </ul>
        <img src={`/header/${_key}.svg`} alt={name} className="product-logo" />
        <div
          className="product-item-borderCover"
          style={{ background: hoverColor }}
        ></div>
        {newProductMap[_key] && (
          <img
            src={'/new-badge.svg'}
            className="new-badge1"
            alt="new-product"
          />
        )}
      </div>
    )
  }
  const lastLine = static_data.product.data.slice(4, 7)
  return (
    <div className="index-new-productIntro">
      <AnimationWrap>
        {/* <div className="index-productIntro-feature mt-104 font-28 t-color block-middle">
        <div
          dangerouslySetInnerHTML={{ __html: static_data.product.feature[0] }}
        />
        <span className="index-productIntro-plus">+</span>
        <div
          dangerouslySetInnerHTML={{ __html: static_data.product.feature[1] }}
        />
      </div> */}
        {/* <div className="mt-64  index-new-productIntro-sectWrap"> */}
        <div className="index-new-productIntro-itemWrap mt-64">
          <div
            style={{ borderBottom: '1px solid #E1E7EF', position: 'relative' }}
          >
            <Item {...static_data.product.data[0]} />
            <div
              className="item extra"
              style={{ backgroundImage: "url('/index/product-top.svg')" }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: static_data.product.feature[0]
                    .replace('<b>', "<span style='font-weight: bold;'>")
                    .replace('</br>', '</span>'),
                }}
              />
            </div>
            <Item {...static_data.product.data[1]} />
            <span className="plusIcon">+</span>
          </div>
          <div>
            <Item {...static_data.product.data[2]} />
            <div
              className="item extra"
              style={{ backgroundImage: "url('/index/product-bottom.svg')" }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: static_data.product.feature[1]
                    .replace('<b>', "<span style='font-weight: bold;'>")
                    .replace('</br>', '</span>'),
                }}
              />
            </div>

            <Item {...static_data.product.data[3]} />
          </div>
          <div>
            {lastLine.map((i: any) => (
              <Item {...i} />
            ))}
          </div>
        </div>
      </AnimationWrap>
    </div>
  )
}
export const MapContainer = ({ white = false }) => {
  return (
    <div className="index-map">
      <div className="index-map-stats">
        <AnimationWrap>
          <h2 className="index-t1 block-middle" style={{ maxWidth: '944px' }}>
            {/* The leading edge services provider in emerging markets */}
            {static_data.map.title}
          </h2>
          <div
            className="font-20 mt-24 block-middle"
            style={{ maxWidth: '681px' }}
          >
            {static_data.map.description}
          </div>
          <div style={{ textAlign: 'center', marginTop: '40px' }}>
            {static_data.map.stats.map(
              ({ name, stats, linkName, link }, idx) => (
                <MapDataBlock
                  key={name}
                  name={name}
                  logo={`/index/stats-${idx + 1}${white ? '-w' : ''}.svg`}
                  stats={stats}
                  linkName={linkName}
                  link={link}
                />
              )
            )}
          </div>
        </AnimationWrap>
      </div>
      <Map />
    </div>
  )
}

const MapDataBlock = ({ name, link, linkName, stats, logo }) => {
  const [visible, setV] = useState(false)
  return (
    <div
      className="index-map-statsItem"
      style={{
        backgroundImage: `url("${logo}")`,
        backgroundRepeat: 'no-repeat',
      }}
    >
      <VisibilitySensor
        onChange={(isVisible) => {
          if (!visible && isVisible) {
            setV(true)
          }
        }}
      >
        <div className="index-map-statsText">
          <div style={{ position: 'relative' }}>
            <span className="font-36" style={{ fontWeight: 600 }}>
              {visible && (
                <span>
                  {stats
                    .split(/([0-9]+)/)
                    .map((str: string) =>
                      /^\d+$/.test(str) ? (
                        <CountUpAnimation>{str}</CountUpAnimation>
                      ) : (
                        str
                      )
                    )}
                </span>
              )}
            </span>
            <div className="font-20">{name}</div>
          </div>
        </div>
      </VisibilitySensor>
    </div>
  )
}
// const CustomerMobile = () => {
//   return (
//     <div className="index-customer full-bg">
//       <div className="index-t1">{static_data.customerStory.title}</div>
//       <div className="index-customer-container mt-56">
//         {static_data.customerStory.data.map(
//           ({ name, tags, stats, link }, i) => (
//             <div
//               className={`index-customer-item block-middle index-customer-item-${
//                 i + 1
//               }`}
//               onClick={() => navigate(`/customer-story/${link}`)}
//             >
//               <img src={`/index/story-${i + 1}-m.jpg`} alt={name} />
//               <div className='index-customer-tags'>
//                 <div
//                   className="font-20 semi-bold t-color"
//                   dangerouslySetInnerHTML={{ __html: name }}
//                 />
//                 {tags.map(t => (
//                   <div className="tag mt-24">{t}</div>
//                 ))}
//               </div>
//             </div>
//           )
//         )}
//       </div>
//     </div>
//   )
// }
export const Customer = () => {
  return (
    <div className="index-customer full-bg">
      <AnimationWrap>
        <div className="index-t1">{static_data.customerStory.title}</div>
        <div className="index-customer-container mt-56">
          {static_data.customerStory.data.map(
            ({ name, tags, stats, link }, i) => (
              <div
                className={`index-customer-item block-middle index-customer-item-${
                  i + 1
                }`}
                onClick={() => navigate(`/customer-story/${link}/`)}
              >
                <div className="left">
                  <picture>
                    <source
                      srcSet={`/index/story-${i + 1}-m.jpg`}
                      media="(max-width: 640px)"
                    />
                    <img src={`/index/story-${i + 1}.png`} alt={name} />
                  </picture>
                  <div
                    style={{ position: 'relative' }}
                    className="left-textContainer"
                  >
                    <div className="left-text">
                      <div
                        className="font-20 semi-bold t-color"
                        dangerouslySetInnerHTML={{ __html: name }}
                      />
                      {tags.map((t) => (
                        <div className="tag mt-24">{t}</div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="right">
                  {stats.map(({ name, value, type }) => (
                    <div
                      className={`stats semi-bold ${type || 'down'}`}
                      style={{
                        backgroundImage: type
                          ? `url("/index/stats-${type}.svg")`
                          : 'none',
                      }}
                    >
                      <div className="font-36 value">{value}</div>
                      <div
                        className="font-20 name"
                        dangerouslySetInnerHTML={{ __html: name }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )
          )}
        </div>
      </AnimationWrap>
      {/* <div style={{ textAlign: "center" }} className="mt-8">
        <Link to="/" className="index-link">
          {static_data.customerStory.linkName}
        </Link>
      </div>

      <div className="mt-48 index-customer-contentWrap">
        <div className="index-customer-content">
          {static_data.customerStory.data.map((d, idx) => (
            <div
              key={idx}
              style={{ margin: "0 12px", display: "inline-block" }}
            >
              <CustomerDisplayBlock img={`/index/story-${idx}.png`} {...d} />
            </div>
          ))}
        </div>
      </div> */}
    </div>
  )
}

// const CustomerDisplayBlock = ({ img, name, costdown, content }) => {
//   return (
//     <div className="index-customer-block">
//       <div className="index-customer-block-imgC">
//         <img src={img} />
//       </div>
//       <p>
//         <div className="index-customer-block-intro">
//           <div className="index-customer-block-intro__left">
//             <span className="font-20 t-color">{name}</span>
//           </div>
//           <div className="index-customer-block-intro__right">
//             <div className="font-28 semi-bold t-color">{costdown}</div>
//             <span className="font-20">
//               {static_data.customerStory.costReduction}
//             </span>
//           </div>
//         </div>
//         <div className="index-customer-block-detail">
//           <ul>
//             {content.map(c => (
//               <li className="font-14">{c}</li>
//             ))}
//           </ul>
//           <div className="font-16">{static_data.customerStory.learnMore}</div>
//         </div>
//       </p>
//     </div>
//   )
// }
const getLocalFile = (obj) => {
  try {
    return obj.localFile.publicURL
  } catch (e) {
    return ''
  }
}
const Feedback = ({ data }) => {
  //
  // let _data = data[0]
  const [active, setActive] = useState(0)
  let newData = [...data]
  if (newData.length === 7) {
    let temp = newData[3]
    newData[3] = newData[6]
    newData[6] = temp
  }
  return (
    <div className="index-feedback">
      <AnimationWrap>
        <div className="index-t1 index-feedback-t1">
          {static_data.feedback.title}
        </div>
        <div className="index-feedback-content">
          <div className="index-feedback-left">
            <div className="index-feedback-iconWraper">
              {newData.map((d, idx) => (
                // <FeedbackIcon key={idx} />
                <div
                  className={`index-feedback-icon ${
                    active === idx ? 'active' : ''
                  }`}
                  onMouseEnter={() => {
                    setActive(idx)
                  }}
                >
                  {d.hoverLogo.localFile && (
                    <img
                      src={d.hoverLogo.localFile.publicURL}
                      alt={d.companyName}
                      className="hover"
                    />
                  )}
                  <img
                    src={d.logo.localFile.publicURL}
                    alt={d.companyName}
                    className="normal"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="index-feedback-right">
            <div className="font-20 semi-bold t-color">
              {newData[active].companyName}
            </div>
            <div className="content">{newData[active].content}</div>
            <div className={`author author-${active}`}>
              <div className="font-16 semi-bold t-color">
                {newData[active].customerName}
              </div>
              <div className="mt-8">{newData[active].customerTitle}</div>
            </div>
            {newData[active].companyPreview && (
              <img
                className="index-feedback-companyPreview"
                src={newData[active].companyPreview.localFile.publicURL}
                alt={newData[active].companyName}
              />
            )}
          </div>
        </div>
      </AnimationWrap>
    </div>
  )
}
export const Banner = ({ banners }) => {
  return (
    <div className="index-banner">
      {/* {JSON.stringify(banners)} */}
      <div className="index-banner-wrapper">
        <Slider {...bannerSetting}>
          {banners.map(({ link, pic, pic_mobile }, idx) => (
            <div>
              <div
                className="index-banner-item"
                onClick={() => {
                  window.open(link)
                }}
              >
                <picture>
                  <source
                    srcSet={getLocalFile(pic_mobile)}
                    media="(max-width: 640px)"
                  />
                  <img src={getLocalFile(pic)} alt={`banner-${idx}`} />
                </picture>
                {/* <picture>
                    <source
                      srcSet={getLocalFile(content_mobile)}
                      media="(max-width: 640px)"
                    />
                    <img
                      src={getLocalFile(content)}
                      alt={`banner-${idx}-conntent`}
                      className="content"
                    />
                  </picture> */}
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {/* <picture>
        <source
          srcSet={`${} 640w `}
          media="(max-width: 640px)"
        />
        <img
          src={`/products/bmc/solution-${lang}.png`}
          alt="bmc-solution"
          className="mobile-padding"
        />
      </picture> */}
    </div>
  )
}
const Partner = ({ data }) => {
  return (
    <div className="index-partner">
      <AnimationWrap>
        <div className="t-color semi-bold font-36">
          {static_data.partner.title}
        </div>
        <div className="index-partner-group mt-64">
          <div className="index-slider">
            <Slider {...settings}>
              {data.map(({ alternativeText, localFile }) => (
                <div className="index-slider-item">
                  <img
                    src={localFile ? localFile.publicURL : ''}
                    alt={alternativeText}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </AnimationWrap>
    </div>
  )
}
const Certification = () => {
  const links = [
    '/press/inc-5000/',
    '/press/zenlayer-recognized-in-financial-times/',
    '/blog/zenlayer-named-hot-sd-wan-startup/',
    '/blog/zenlayer-wins-ptc-networking-innovation-award/',
    '/blog/joe-zhu-named-to-apac50/',
    '/press/streaming-media-top-companies/',
  ]
  return (
    <div className="index-cert">
      <AnimationWrap>
        <div className="page-middle">
          <div className="index-cert-content">
            <div className="font-20 index-cert-desc">
              {static_data.certification.title}
            </div>
            <div className="imgContainer">
              {links.map((link, idx) => (
                <img
                  src={`/index/credit-${idx + 1}.png`}
                  alt={`credit-${idx + 1}`}
                  onClick={() => {
                    if (lang === 'en') {
                      navigate(link)
                    }
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </AnimationWrap>
    </div>
  )
}
export const GetStarted = ({
  title = static_data.bottomBanner.title,
  source,
}) => {
  return (
    <div className="index-getStart">
      <div className="index-getStart-bg full-bg">
        <AnimationWrap>
          <div className="page-middle">
            <h2 className="index-getStart-t1 font-36 semi-bold white">
              {title}
            </h2>
            <ConsultationPopButton
              buttonClassName="mt-36 white"
              source={source}
              buttonWidth={290}
            >
              {static_data.bottomBanner.button}
            </ConsultationPopButton>
          </div>
        </AnimationWrap>
      </div>
    </div>
  )
}

export default function Home({ data }) {
  // const addClickHandler = () => {
  //   const bmcServer = document.querySelector('.promo')
  //   const handler = (e: any) => {
  //     let closest = e.target.closest('.svg-dn-btn')
  //     if (closest) {
  //       window.open(
  //         `https://console.zenlayer.com/bmc/server/create?referral=${encodeURIComponent(
  //           `${location.origin}/${location.pathname}`
  //         )}`
  //       )
  //       // jumpToSignin(closest.dataset.utm_id)
  //     }
  //   }
  //   if (bmcServer) {
  //     bmcServer.addEventListener('click', handler)
  //   }
  //   return () => {
  //     if (bmcServer) {
  //       bmcServer.removeEventListener('click', handler)
  //     }
  //   }
  // }
  useEffect(() => {
    addEventListener('scroll', (evt) => {
      //
      let scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop

      const leftElementLink: any = document.getElementById(
        'parallax-effect-pgId'
      )
      const count = scrollTop - (240 + 181)

      if (leftElementLink) {
        leftElementLink.style.backgroundPosition = `50% ${
          scrollTop >= 386 ? -35 : count
        }%`
      }
    })
    // return addClickHandler()
  }, [])

  const { allStrapiTestimonials, strapiIndex, allStrapiBanners } = data
  const banners = allStrapiBanners.nodes.filter((banner) => !!banner.link)
  const hasBanner = banners.length > 0
  console.log(banners)
  return (
    <div>
      <SEO {...static_data.seo} />
      <div
        className={`index-topContainer ${hasBanner ? 'withBanner' : ''}`}
        style={hasBanner && lang === 'zh' ? { minHeight: '600px' } : {}}
        // style={}
      >
        <div className="index-topBgContainer">
          <div className="index-topBg" />
        </div>

        <div className="index-topBg-dotWrapper">
          <div className="index-topBg-dot moveupdown" />
        </div>
        <div className="headerContainer">
          <Header logo="/logo-blue.svg" />
        </div>
        <TopIntro />
      </div>
      {/* <div className="promo">
        <picture>
          <source
            srcSet={`/index/bmc-promotion-m.svg 640w`}
            media="(max-width: 640px)"
          />
          <img
            src={`/index/bmc-promotion.svg`}
            onClick={() => {
              window.open(
                'https://console.zenlayer.com/bmc/server/create?promotion_tag=2210_bmc'
              )
            }}
          />
        </picture>
      </div> */}

      <div className="only-desktop">
        <ProductIntro />
      </div>
      <div className="only-mobile">
        <ProductIntroM />
      </div>
      {hasBanner && <Banner banners={banners} />}

      <MapContainer white={true} />
      {/* <div className="only-desktop"> */}
      <Customer />
      {/* </div> */}
      {/* <div className="only-mobile"> */}
      {/* <CustomerMobile /> */}
      {/* </div> */}

      <Feedback data={allStrapiTestimonials.edges.map((n) => n.node)} />
      <Partner data={strapiIndex.indexPartnerLogos} />
      <Certification />
      <GetStarted source="index" />
      <Footer />
    </div>
  )
}

export const query = graphql`
  {
    strapiIndex {
      indexPartnerLogos {
        localFile {
          publicURL
        }
        alternativeText
      }
    }
    allStrapiBanners(filter: { displayed_in: { eq: "index" } }) {
      nodes {
        pic {
          localFile {
            publicURL
          }
        }
        pic_mobile {
          localFile {
            publicURL
          }
        }
        link
      }
    }
    allStrapiTestimonials(filter: { inPage: { eq: "index" } }) {
      edges {
        node {
          customerName
          customerTitle
          content
          companyName
          logo {
            localFile {
              publicURL
            }
          }
          hoverLogo {
            localFile {
              publicURL
            }
          }
          companyPreview {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
